"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calls = void 0;
exports.calls = {
    UniqueLinking: [
        {
            version: 1,
            methods: {
                address_for_name: {
                    description: 'Looks up a nickname, returning the address it is linked to or None',
                    params: [{ name: 'name', type: 'Text' }],
                    type: 'Option<NameLookupResult>',
                },
                batch_address_for_name: {
                    description: 'Looks up multiple nicknames at once. For each name it returns either the address it is linked to or None',
                    params: [{ name: 'names', type: 'Vec<Text>' }],
                    type: 'Vec<Option<NameLookupResult>>',
                },
                name_for_address: {
                    description: 'Looks up an address, returned the nickname that is linked to it or None.',
                    params: [{ name: 'address', type: 'DidApiAccountId' }],
                    type: 'Option<AccountLookupResult>',
                },
                batch_name_for_address: {
                    description: 'Looks up multiple addresses at once. For each address it returns either the nickname it is linked to or None',
                    params: [{ name: 'addresses', type: 'Vec<DidApiAccountId>' }],
                    type: 'Vec<Option<AccountLookupResult>>',
                },
            },
        },
    ],
};
