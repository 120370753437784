"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calls = void 0;
exports.calls = {
    BondedCurrency: [
        {
            version: 1,
            methods: {
                pool_info: {
                    description: 'Query the pool status in a human readable format.',
                    params: [{ name: 'pool_id', type: 'PoolId' }],
                    type: 'Result<HumanReadablePoolDetails, BondedCurrencyError>',
                },
                pool_infos: {
                    description: 'Query the pools status in a human readable format.',
                    params: [{ name: 'pool_ids', type: 'Vec<PoolId>' }],
                    type: 'Result<Vec<HumanReadablePoolDetails>, BondedCurrencyError>',
                },
                quote_for: {
                    description: 'Calculates the collateral for the given amount. The operation is determining whether the amount is minted or burned. The calculated collateral amount is based on the current state of the pool.',
                    params: [
                        { name: 'pool_id', type: 'PoolId' },
                        { name: 'currency_idx', type: 'u8' },
                        { name: 'operation', type: 'MintBurnOperation' },
                    ],
                    type: 'Result<CollateralBalance, BondedCurrencyError>',
                },
                quote_for_low_and_high_bounds: {
                    description: 'Calculates the collateral for the given integral bounds lower and upper. This function computes the collateral amount based on the provided lower and upper bounds, regardless of the current state.',
                    params: [
                        { name: 'pool_id', type: 'PoolId' },
                        { name: 'currency_idx', type: 'u8' },
                        { name: 'low', type: 'BondedCurrenciesBalance' },
                        { name: 'high', type: 'BondedCurrenciesBalance' },
                        { name: 'rounding', type: 'RoundingKind' },
                    ],
                    type: 'Result<CollateralBalance, BondedCurrencyError>',
                },
                decode_curve_coefficient: {
                    description: 'Parses the bit representation for the coefficient to a human readable format.',
                    params: [{ name: 'bit_representation', type: 'FixedPointBitsType' }],
                    type: 'Result<Text, BondedCurrencyError>',
                },
                encode_curve_coefficient: {
                    description: 'Calculates the bit representation for the coefficient. The coefficient is constructed by `coefficient_int.coefficient_frac`. The first value in the tuple is the internal calculated coefficient, represented as a Text. The second value is the bit representation.',
                    params: [{ name: 'coefficient', type: 'Text' }],
                    type: 'Result<EncodedCoefficient, BondedCurrencyError>',
                },
                query_pools_by_owner: {
                    description: 'Query all pool IDs where the given account is the owner.',
                    params: [{ name: 'account', type: 'AccountId32' }],
                    type: 'Vec<HumanReadablePoolDetails>',
                },
                query_pools_by_manager: {
                    description: 'Query all pool IDs where the given account is the manager.',
                    params: [{ name: 'account', type: 'AccountId32' }],
                    type: 'Vec<HumanReadablePoolDetails>',
                },
            },
        },
    ],
};
